<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#F1F4FB;"
      d="M448,99.002c0-4.85-1.927-9.501-5.355-12.93L361.928,5.355C358.498,1.927,353.848,0,348.998,0H82.286
	C72.187,0,64,8.186,64,18.286v475.429C64,503.813,72.187,512,82.286,512h347.429c10.098,0,18.286-8.187,18.286-18.286V99.002z"
    />
    <circle style="fill:#FFFFFF;" cx="256" cy="338.286" r="128" />
    <g>
      <path
        style="fill:#B4E66E;"
        d="M173.714,116.584v-33.41C185.057,85.282,192,90.943,192,95.143c0,5.054,4.089,9.143,9.143,9.143
		s9.143-4.089,9.143-9.143c0-15.323-15.465-27.722-36.571-30.534V64c0-5.054-4.089-9.143-9.143-9.143s-9.143,4.089-9.143,9.143
		v0.609c-21.106,2.811-36.571,15.21-36.571,30.534c0,21.693,20.037,30.077,36.571,35.13v33.41
		c-11.343-2.109-18.286-7.769-18.286-11.969c0-5.054-4.089-9.143-9.143-9.143c-5.054,0-9.143,4.089-9.143,9.143
		c0,15.323,15.465,27.722,36.571,30.534v0.609c0,5.054,4.089,9.143,9.143,9.143s9.143-4.089,9.143-9.143v-0.609
		c21.106-2.811,36.571-15.21,36.571-30.534C210.286,130.022,190.249,121.638,173.714,116.584z M137.143,95.143
		c0-4.2,6.943-9.861,18.286-11.969v27.749C141.957,106.118,137.143,101.758,137.143,95.143z M173.714,163.683v-27.749
		c13.472,4.805,18.286,9.165,18.286,15.779C192,155.914,185.057,161.575,173.714,163.683z"
      />
      <path
        style="fill:#B4E66E;"
        d="M310.857,91.429h-73.143c-5.054,0-9.143-4.089-9.143-9.143s4.089-9.143,9.143-9.143h73.143
		c5.054,0,9.143,4.089,9.143,9.143S315.911,91.429,310.857,91.429z"
      />
    </g>
    <g>
      <path
        style="fill:#959CB5;"
        d="M265.143,128h-27.429c-5.054,0-9.143-4.089-9.143-9.143s4.089-9.143,9.143-9.143h27.429
		c5.054,0,9.143,4.089,9.143,9.143S270.197,128,265.143,128z"
      />
      <path
        style="fill:#959CB5;"
        d="M320,128h-18.286c-5.054,0-9.143-4.089-9.143-9.143s4.089-9.143,9.143-9.143H320
		c5.054,0,9.143,4.089,9.143,9.143S325.054,128,320,128z"
      />
    </g>
    <path
      style="fill:#AFB9D2;"
      d="M356.571,164.571H237.714c-5.054,0-9.143-4.089-9.143-9.143s4.089-9.143,9.143-9.143h118.857
	c5.054,0,9.143,4.089,9.143,9.143S361.625,164.571,356.571,164.571z"
    />
    <path
      style="fill:#D5DCED;"
      d="M356.571,82.286V1.699C354.209,0.621,351.643,0,348.998,0h-10.712v82.286
	c0,15.125,12.304,27.429,27.429,27.429H448V99.002c0-2.647-0.621-5.213-1.699-7.574h-80.586
	C360.67,91.429,356.571,87.33,356.571,82.286z"
    />
    <path
      style="fill:#B4E66E;"
      d="M193.482,428.384C211.227,440.72,232.751,448,256,448c53.32,0,97.717-38.049,107.617-88.469
	L256,338.286L193.482,428.384z"
    />
    <path
      style="fill:#FFC850;"
      d="M199.608,432.27L256,338.286l-107.139,23.456C155.385,391.671,174.139,416.952,199.608,432.27z"
    />
    <path
      style="fill:#FFDC64;"
      d="M184.65,255.045c-23.453,20.121-38.365,49.917-38.365,83.241c0,12.131,2.047,23.767,5.683,34.677
	L256,338.286L184.65,255.045z"
    />
    <path
      style="fill:#C8FF82;"
      d="M256,228.571c-28.419,0-54.23,10.898-73.718,28.624L256,338.286l105.426,30.121
	c2.734-9.582,4.288-19.662,4.288-30.121C365.714,277.691,316.594,228.571,256,228.571z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
